import { BASE_BACKEND_URL, REPLACE_TOKEN } from './constants';
import { Environment } from "./models";
import { version } from './version';

export const environment: Environment = {
  production: false,
  apiUrl: `https://${REPLACE_TOKEN}.dev.${BASE_BACKEND_URL}`,
  version: version + 'beta',
  doTracking: true,
  keys: {
    MIX_PANEL_KEY: "a840de63ed55222717e86b7cd994da46",
    GOOGLE_MAPS_KEY: "AIzaSyBAXGRdo-5G0AFTRjUXc1pkoGrQbN2Mz1I"
  }
};
